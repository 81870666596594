<template>
  <div class="login_container">
    <TopNav />

    <div class="vh-100 d-flex justify-content-center my-5">
      <div class="form-access my-auto glass_a">
        <form @submit.prevent="submitForm">
          <span>Masuk</span>
          <div class="form-group">
            <input
              type="email"
              class="form-control"
              placeholder="Alamat Email"
              v-model="email"
              @click="isError = false"
            />
          </div>
          <div class="form-group position-relative">
            <input
              type="password"
              class="form-control"
              placeholder="Password"
              ref="password"
              v-model="password"
              @click="isError = false"
            />
            <TogglePassword @is-password-show="togglePasswordValue" />
          </div>
          <div class="form-group">
            <VueRecaptcha
              ref="recaptcha"
              @verify="onVerify"
              :sitekey="recapSiteKey"
            ></VueRecaptcha>
          </div>
          <button type="submit" class="btn btn_gradient">
            <span v-if="!loading" class="m-0" style="font-size: 1rem"
              >Kirim</span
            >
            <span
              v-else
              class="spinner-border spinner-border-sm mx-auto my-0"
              aria-hidden="true"
            ></span>
          </button>
        </form>

        <!-- errr response -->
        <div v-show="isError" class="err_box">
          <p class="m-0 text-center">{{ errorMessage }}</p>
        </div>

        <h2>
          Belum mempunyai akun?
          <router-link to="/auth/register" class="primary-link">
            Daftar di sini
          </router-link>
        </h2>
        <h2>
          Lupa password?
          <router-link to="/auth/forgot-password" class="primary-link">
            Klik di sini
          </router-link>
        </h2>
        <h2>
          Belum menerima email verifikasi?
          <router-link
            to="/auth/email-verification-token-request"
            class="primary-link"
          >
            Klik di sini
          </router-link>
        </h2>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import { VueRecaptcha } from "vue-recaptcha";
import Cookies from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import TogglePassword from "@/components/global/TogglePassword.vue";

export default {
  components: {
    TopNav,
    Footer,
    TogglePassword,
    VueRecaptcha,
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      email: "",
      password: "",
      recapSiteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,

      recaptchaToken: "",
      errorMessage: null,
      isError: false,
      loading: false,
    };
  },

  async mounted() {
    var self = this;

    if (self.token) {
      self.$router.push("/");
    }
  },

  methods: {
    async submitForm() {
      var self = this;
      this.loading = true;

      // TODO: Jangan bypass di production
      // if (self.recaptchaToken != "") {
      var res = await thestore.postAuthLogin(
        self.email,
        self.password,
        self.recaptchaToken
      );

      if (res.success == true && res.action == "login_complete") {
        Cookies.set("jwt", res.token, {
          expires: 2,
          secure: true,
          sameSite: "lax",
        }); // expire 7 days. CAUTION: coba kurangi jika perlu
        Cookies.set("usid", res.usid, {
          expires: 2,
          secure: true,
          sameSite: "lax",
        });
        self.$router.go("/");
      } else if (res.success == true && res.action == "2fa") {
        Cookies.set("temp_email", self.email, {
          expires: 1,
          secure: true,
          sameSite: "lax",
        });
        Cookies.set("temp_password", self.password, {
          expires: 1,
          secure: true,
          sameSite: "lax",
        });
        self.$router.push("/auth/two-factor-auth");
      } else {
        self.isError = true;
        self.errorMessage = res.msg;
        // alert(res.msg);
        if (res.msg == "Wrong captcha") {
          setTimeout(() => {
            self.$router.go();
          }, 2000);
        }
      }

      this.loading = false;
    },

    onVerify(response) {
      var self = this;
      if (response) {
        // User input is valid
        self.recaptchaToken = response;
      } else {
        // User input is invalid
        self.recaptchaToken = "";
      }
    },

    togglePasswordValue(val) {
      const password = this.$refs.password;
      password.type = val ? "text" : "password";
    },
  },
};
</script>

<style scoped>
.login_container {
  position: relative;
  height: 100%;
  background-image: url("/images/bg-02.png");
  background-repeat: no-repeat;
  background-position: bottom;
}

.form-control {
  color: black !important;
}

.btn_gradient {
  background-color: #708cc7 !important;
  color: #fff;
}

.btn_gradient:hover {
  background-color: #5572b1 !important;
}

.primary-link {
  color: #708cc7;
}

.glass_a {
  /* From https://css.glass */
  background: linear-gradient(
    114deg,
    rgba(121, 67, 239, 0.18) 0%,
    rgba(108, 36, 161, 0.29) 36%,
    rgba(33, 93, 154, 0.28) 72%,
    rgba(146, 105, 197, 0.43) 100%
  );

  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 30px 0;
}

.err_box {
  max-width: 340px;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: palevioletred;
  border-radius: 5px;
  padding: 1rem;
  margin: 0 30px;
}

@media only screen and (min-width: 768px) {
  .glass_a {
    padding: 30px;
  }
}
</style>
