<template>
  <div>
    <div v-html="theText"></div>
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";

// applibs
import thestore from "@/applibs/thestore.js";

// components
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      theText: "",
    };
  },

  async mounted() {
    const agtext = await thestore.getUserAgreementText(this.token);
    // console.log('agtext', agtext);
    this.theText = agtext;
  },
};
</script>

<style scoped>
h1 {
  font-size: 2em;
  margin-bottom: 2em;
  font-weight: bold;
}

h2 {
  font-size: 1.5em;
  margin-bottom: 1em;
  margin-top: 2em;
  font-weight: bold;
}

h3 {
  font-size: 1em !important;
  margin-bottom: 1em;
  margin-top: 1em;
  font-weight: bold;
}
</style>
